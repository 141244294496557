import React, { useState, useEffect } from "react"
import { Loader } from '@reactiveonline/frontend_shared_components'

export default function Invoice({
  translations, selectedCountry, selectedMethod, invoiceState, setInvoiceState,
  clearError, showInvoiceForm, currentInvoice, companyCheckout
}) {
  const [loading, setLoading] = useState(false)
  const [lockVatField, setLockVatField] = useState(false)
  const [vatValidity, setVatValidity] = useState('')

  useEffect( ()=>{
    if ( invoiceState.companyName.length > 0 ) {
      clearError('company-name')
    }
  },[invoiceState.companyName] )

  function vatValidation(){
    setLoading(true)
    setLockVatField(true)

    let data = new FormData()
    data.append("vat_number", invoiceState.vatNumber)
    data.append("country_code", selectedCountry.value)

    Rails.ajax({
      url: `/reservation/vat_validation`,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: res => {
        setLoading(false)
        setVatValidity('valid')
        setInvoiceState( prevState => ({ ...prevState, companyName: res.companyName }) )
      },
      error: res => {
        setLoading(false)
        setVatValidity('invalid')
      }
    })
  }

  return(
    <>
      { (showInvoiceForm || !!currentInvoice) &&
        <div className='fields'>
          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { translations.invoices.company_name }
                </label>
                <div style={{marginRight: '5px'}}>*</div>
              </div>
              <input
                id="company-name"
                type="text"
                value={ invoiceState.companyName }
                onChange={ (e) => setInvoiceState( prevState => ({ ...prevState, companyName: e.target.value }) ) }
                disabled={ companyCheckout }
              />
            </div>

            { selectedCountry &&
              <div className="field" style={vatValidity === 'invalid' ? {marginTop: 0} : {}}>
                <div className='flex-box content-space-between' style={{ position: 'relative' }}>
                  <label style={{ display: 'inline-block' }}>
                    { translations.invoices.vat_number }
                    { vatValidity === 'invalid' && <span style={{ fontSize: 12, color: '#575757' }}>&nbsp;{ translations.invoices.invalid_vat }</span> }
                  </label>
                  { loading &&
                    <Loader
                      absolute
                      size={ 'small' }
                      position={ 'right' }
                    />
                  }
                </div>
                <div className='input-field' style={{ position: 'relative' }}>
                  <input
                    id="vat-number"
                    type="text"
                    disabled={ companyCheckout || lockVatField }
                    style={ selectedCountry.region === 'EU' ? {position: 'relative', paddingLeft: 28} : {} }
                    className={ lockVatField ? 'disabled' : '' }
                    value={ invoiceState.vatNumber }
                    onChange={ (e) => setInvoiceState( prevState => ({ ...prevState, vatNumber: e.target.value }) ) }
                  >
                  </input>
                  { selectedCountry.region === 'EU' &&
                    <>
                      <div style={{ fontSize: 12, position: 'absolute', left: 10, top: '50%', color: '#575757', textTransform: 'uppercase', marginTop: '-9px' }}>{ selectedCountry ? selectedCountry.label.substring(0,2) : null }</div>
                      { !(companyCheckout || lockVatField) &&
                        <>
                          { vatValidity === '' && invoiceState.vatNumber.length >= 9 &&
                            <div
                              onClick={ ()=> vatValidation() }
                              className='pointer'
                              style={{ fontSize: 12, position: 'absolute', right: 20, top: '50%', marginTop: '-9px' }}
                            >
                              { "translations.invoices.validate" }
                            </div>
                          }
                          <div
                            className={`pointer flex-box items-center ${vatValidity === 'invalid' ? 'close_icon' : '' } ${vatValidity === 'valid' ? 'check_icon valid' : '' } `}
                            style={{ position: 'absolute', top: '50%', right: 20, fontSize: 13, marginTop: '-9px' }}
                            onClick={ ()=> {
                            setInvoiceState( prevState => ({ ...prevState, vatNumber: '', companyName: '', additionalInfo: '' }) ),
                            setLockVatField(false), setVatValidity('') } }
                          >
                            { selectedCountry.region === 'EU' && (vatValidity === 'invalid' || vatValidity === 'valid') &&
                              <b style={{ marginLeft: 5 }}>
                                { translations.company.clear }
                              </b>
                            }
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            }
          </div>
          <div className="field-columns">
            <div className="field notes" style={{ width: '100%' }}>
              <div className='flex-box items-center'>
                <label>
                  { translations.invoices.additional_info }
                </label>
              </div>
              <textarea
                id="additional_info"
                value={ invoiceState.additionalInfo }
                onChange={ (e) => setInvoiceState( prevState => ({ ...prevState, additionalInfo: e.target.value }) ) }
                disabled={ companyCheckout }
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}
