import React, { useState, useEffect, useRef } from 'react'
import { FlashMessage, Modal } from '@reactiveonline/frontend_shared_components'
import { validateEmail, validatePhoneNumber } from '@reactiveonline/frontend_shared_components/utils'
import Select from "react-select"
import Invoice from './Invoice'
import PaymentMethod from './methods/PaymentMethod'
import { customReactSelectStyles, customReactSelectTheme, numberValidator } from "../../helpers/utils"
import ErrorMessage from '../shared/ErrorMessage'
import { paymentIFrame } from './helpers/subComponents'
import { loader } from './helpers/subComponents'

export default function PaymentStep ({
  appProps, stripePublishableKey, orderId, type,  guestCheckout, canIssueInvoice, billingAddress, setBillingAddress, contactDetails, invoice, showInvoiceForm,
  setShowInvoiceForm, setLoading, currency, couponsValidationAfterAction, cardsPath, setCurrentCardPath, legalTerms
}) {
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedMethod, setSelectedMethod] = useState({})
  const [acceptedTerms, setAcceptedTerms]   = useState(false)
  const [showTerms, setShowTerms]           = useState(false)
  const [availableCards, setAvailableCards] = useState(false)

  const [availableCountries, setAvailableCountries] = useState([])
  const [availableStates, setAvailableStates] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState([])
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [invoiceState, setInvoiceState] = useState({
    companyName: invoice ? invoice.company_name : '',
    vatNumber: invoice ? invoice.vat_number : '',
    additionalInfo: invoice ? invoice.additional_info : ''
  })
  const [statesRequired, setStatesRequired] = useState(false)

  // STRIPE
  const [submitStripeCard, setSubmitStripeCard] = useState(false)
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] = useState(null)
  const [stripePaymentIntentActionType, setStripePaymentIntentActionType] = useState(null)
  const [stripeSetupIntentClientSecret, setStripeSetupIntentClientSecret] = useState(null)
  const [stripeSetupIntentActionType, setStripeSetupIntentActionType] = useState(null)
  // SIX
  const [showPaymentIframe, setShowPaymentIframe] = useState(false)

  const flashMessageRef = useRef(null)

  useEffect(() => {
    Rails.ajax({
      url: appProps.paymentMethodsPath,
      type: 'get',
      success: (res) => {
        setPaymentMethods(res)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedMethod.billingAddressRequired || (showInvoiceForm || !!invoice)) {
      setShowAddressForm(true)
      billingAddressInit()
    } else {
      setShowAddressForm(false)
    }
  },[showInvoiceForm, selectedMethod])

  function escFunction(event){
    if (event.key === "Escape") {
      setShowTerms(false)
    }
  }
  useEffect( ()=>{
    document.addEventListener("keydown", escFunction, false);
  },[showTerms])

  const clearErrors = () => {
    document.querySelectorAll('[id$="-error"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')
  }

  function clearError(id){
    let errorElement = document.getElementById(`${id}-error`)
    if (errorElement) {
      errorElement.remove()
      errorElement.style.borderColor = ''
    }
  }

  const handleSubmit = () => {
    let noErrors = true
    if(Object.values(billingAddress).filter(value => value).length > 0){
      clearErrors();

      if ( billingAddress.fullName.length === 0 ) {
        ErrorMessage("billing-full-name", "red", "billing-full-name-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
        return;
      }
      if ( !validatePhoneNumber(billingAddress.phone) && !validatePhoneNumber(billingAddress.mobilePhone) ) {
        ErrorMessage("billing-phone", "red", "billing-phone-error", `${appProps.translations.flash_messages.phone_invalid}`, "red")
        return;
      }
      if ( billingAddress.address.length === 0 ) {
        ErrorMessage("billing-street", "red", "billing-street-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
        return;
      }
      if ( billingAddress.city.length === 0 ) {
        ErrorMessage("billing-city", "red", "billing-city-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
        return;
      }
      if ( !billingAddress.country ) {
        ErrorMessage("billing-country", "red", "billing-country-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
        return;
      }
      if ( billingAddress.postalCode.length === 0 ) {
        ErrorMessage("billing-postal_code", "red", "billing-postal_code-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
        return;
      }

      if (selectedCountry && selectedCountry.states_required && billingAddress.area.length === 0) {
        ErrorMessage("billing-area", "red", "billing-area-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
        return;
      }
    }

    if (Object.keys(selectedMethod).length === 0) {
      appProps.flashMessage.show(appProps.translations.flash_messages.select_payment_method, 'error')
      return;
    }

    if (!acceptedTerms) {
      appProps.flashMessage.show(appProps.translations.flash_messages.accept_terms_error, 'error')
      return;
    }

    if (showInvoiceForm || !!invoice){
      if( !invoiceState.companyName || (invoiceState.companyName && invoiceState.companyName.length === 0) ) {
        ErrorMessage("company-name", "red", "company-name-error", `${appProps.translations.reservations.general_error} ${appProps.translations.invoices.company_name}`, "red")
        noErrors = false
      } else if(noErrors) {
        setBillingAddress(prev => ({ ...prev, companyName: invoiceState.companyName, vatNumber: invoiceState.vatNumber, additional_info: invoiceState.additionalInfo}))

        let fd = new FormData()
        fd.append('invoice[company_name]', invoiceState.companyName)
        fd.append('invoice[vat_number]', invoiceState.vatNumber)
        fd.append('invoice[additional_info]', invoiceState.additionalInfo)

        Rails.ajax({
          type: 'POST',
          url: '/reservation/invoice',
          dataType: 'json',
          data: fd,
          success: res => {
            // googleMeasurementId ? checkoutPaymentMethodAnalytics(selectedMethod) : checkoutPaymentMethodLegacyAnalytics(selectedMethod);
            submitPaymentStep()
          }
        })
      }
    } else {
      if (selectedMethod.type === "StripePayment") {
        setLoading(true)
        setSubmitStripeCard(true)
      } else {
        return noErrors ? submitPaymentStep() : null
      }
    }
  }

  const setStripeError = () => {
    setSubmitStripeCard(false)
    setStripePaymentIntentActionType(null)
    setStripePaymentIntentClientSecret(null)
    setStripeSetupIntentActionType(null)
    setStripeSetupIntentClientSecret(null)
    setLoading(false)
  }

  const submitPaymentStep = (stripePaymentMethodId = null) => {
    setLoading(true)
    let fd = new FormData()

    fd.append('reservation[payment_method_id]', selectedMethod.id)

    if (showAddressForm){
      fd.append('reservation[billing_address_attributes][full_name]', billingAddress.fullName)
      fd.append('reservation[billing_address_attributes][street]', billingAddress.address)
      fd.append('reservation[billing_address_attributes][city]', billingAddress.city)
      fd.append('reservation[billing_address_attributes][postal_code]', billingAddress.postalCode)
      fd.append('reservation[billing_address_attributes][country_numeric_code]', billingAddress.country)
      fd.append('reservation[billing_address_attributes][state_region_code]', billingAddress.area)
      fd.append('reservation[billing_address_attributes][phone]', billingAddress.phone)
      fd.append('reservation[billing_address_attributes][mobile_phone]', billingAddress.mobile)
    }

    if (stripePaymentMethodId) fd.append('stripe_payment_method_id', stripePaymentMethodId);

    Rails.ajax({
      url: appProps.reservationPath,
      type: 'PATCH',
      contentType: 'application/json',
      dataType: 'json',
      data: fd,
      success: response => {
        if(response.redirect_url) {
          location.href = response.redirect_url
        } else if(response.redirect_form) {
          document.body.innerHTML += response.redirect_form
          const form = document.getElementById('payment-redirect-form')
          form.submit()

        } else if((response.requires_action || response.requires_confirmation) && (response.payment_intent_client_secret || response.setup_intent_client_secret)) {
          setSubmitStripeCard(false)
          if (response.payment_intent_client_secret) {
            setStripePaymentIntentActionType(response.requires_action ? 'requires_action' : 'requires_confirmation')
            setStripePaymentIntentClientSecret(response.payment_intent_client_secret)
            setStripeSetupIntentActionType(null)
            setStripeSetupIntentClientSecret(null)
          } else {
            setStripePaymentIntentActionType(null)
            setStripePaymentIntentClientSecret(null)
            setStripeSetupIntentActionType(response.requires_action ? 'requires_action' : 'requires_confirmation')
            setStripeSetupIntentClientSecret(response.setup_intent_client_secret)
          }

        } else if(response.redirect_iframe_url) {
          setShowPaymentIframe(true)
          document.getElementById('payment-iframe').src = response.redirect_iframe_url
        } else if (response.couponsNotice) {
          couponsValidationAfterAction(res)
        }
      },
      error: response => {
        appProps.flashMessage.show(response.error, 'error')
        setSubmitStripeCard(false)
        setStripePaymentIntentClientSecret(null)
        setLoading(false)
      }
    })
  }

  function getAvailableCountries(){
    Rails.ajax({
      type: "GET",
      url: `/${ appProps.currentLocale }/countries`,
      dataType: "json",
      success: res => {
        setAvailableCountries(res.available_countries)
      }
    })
  }

  function getAvailableStates(selectedCountry){
    Rails.ajax({
      type: "GET",
      url: `/${ appProps.currentLocale }/countries/${selectedCountry}/states`,
      dataType: "json",
      success: res => {
        setSelectedState(null)
        setStatesRequired(res.available_states.length > 0)
        setAvailableStates(res.available_states)
      }
    })
  }

  function handleCountryChange(selected) {
    setSelectedCountry(selected)
    getAvailableStates(selected.value)
    setBillingAddress(prev => ({ ...prev, country: selected.value}))
  }

  function billingAddressInit(){
    const addressKeys = ['address', 'city', 'country', 'area', 'fullName', 'mobile', 'phone', 'postalCode']
    let addressProps = Object.assign({}, ...addressKeys.map( key => ({ [key]: billingAddress[key] }) ))

    if (!addressKeys.find( key => addressProps[key] )) { // if all billingProps are empty then initialize from contactAddress
      addressProps.mobile = contactDetails.mobile_phone
      addressProps.phone = contactDetails.phone
      addressProps.fullName = contactDetails.full_name
    }

    setBillingAddress(prevBillingAddress => ({
      ...prevBillingAddress,
      fullName: addressProps.fullName,
      mobile: addressProps.mobile && addressProps.mobile.trim(),
      phone: addressProps.phone && addressProps.phone.trim(),
    }))
  }

  function handleStateChange(selected) {
    setSelectedState(selected)
    setBillingAddress(prev => ({ ...prev, area: selected.value }))
  }

  return (
    <>
      <div className="card checkout-step flex-box flex-column payment">
        <h3><b>{ appProps.translations.reservations.choose_payment }</b></h3>
        <div id="payment-methods-list" className="step-editor flex-box flex-wrap payment-methods">
          {
            paymentMethods.map((method, index) => {
              return(
                <PaymentMethod
                  key            = { index          }
                  appProps       = { appProps }
                  clearErrors    = { clearErrors }
                  paymentMethod  = { method                      }
                  selectedMethod = { selectedMethod              }
                  updateSelectedPayment = { setSelectedMethod }
                  showCost = { true }
                  stripePublishableKey = { method.type === "StripePayment" ? stripePublishableKey : null }
                  submitStripeCard = { submitStripeCard }
                  stripePaymentIntentClientSecret = { stripePaymentIntentClientSecret }
                  stripePaymentIntentActionType={ stripePaymentIntentActionType }
                  stripeSetupIntentClientSecret = { stripeSetupIntentClientSecret }
                  stripeSetupIntentActionType={ stripeSetupIntentActionType }
                  setStripeError = { setStripeError }
                  completeOrder = { submitPaymentStep }
                  currency = { currency }
                  orderId = { orderId }
                  type = { type }
                  cardsPath = { cardsPath }
                  setCurrentCardPath={ setCurrentCardPath }
                  setLoading={ setLoading }
                  billingDetails = { {
                    address: {
                      city: billingAddress.city,
                      country: availableCountries?.find(c => c.numeric_code === billingAddress.country)?.alpha2_code,
                      line1: billingAddress.address,
                      postal_code: billingAddress.postalCode,
                      state: availableStates?.find(s => s.value === billingAddress.area)?.label
                    },
                    name: billingAddress.fullName,
                    phone: billingAddress.mobile || billingAddress.phone,
                    email: contactDetails.email
                  } }
                />
              )
            })
          }

          { showPaymentIframe &&
            <div className="payment-iframe-overlay">
              <iframe id="payment-iframe" src="" width="500" height="500"> </iframe>
            </div>
          }
        </div>

        { canIssueInvoice &&
          <div className="field">
            <div>
              <div className="switch-wrapper">
                <div className="flex-box items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      defaultChecked={ showInvoiceForm || !!invoice }
                      onChange={ () => setShowInvoiceForm(prev => !prev) }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div  className="switch-text">
                  { appProps.translations.reservations.create_invoice }
                </div>
              </div>
            </div>
          </div>
        }

        { showAddressForm &&
          <div className="fields">
            <div className="field-columns">
              <div className="field">
                <div className='flex-box items-center'>
                  <label>
                    { appProps.translations.reservations.full_name }
                  </label>
                  <div style={{marginLeft: 5}}>*</div>
                </div>
                <input
                  id="billing-full-name"
                  type="text"
                  value={ billingAddress.fullName || '' }
                  onChange={ e => setBillingAddress(prev => ({ ...prev, fullName: e.target.value})) }
                />
              </div>

              <div id="phone" className="field">
                <div className='flex-box items-center'>
                  <label>
                    { appProps.translations.reservations.phone }
                  </label>
                  <div style={{marginLeft: 5}}>*</div>
                </div>
                <input
                  id="billing-phone"
                  type="text"
                  value={ billingAddress.phone || '' }
                  onChange={ e => setBillingAddress(prev => ({ ...prev, phone: e.target.value})) }
                />
              </div>

              <div id="mobile-phone" className="field">
                <div className='flex-box items-center'>
                  <label>
                    { appProps.translations.reservations.mobile }
                  </label>
                  {/* { requireMobilePhone && <div style={{marginLeft: 5}}>*</div> } */}
                </div>
                <input
                  id="billing-mobile-phone"
                  type="text"
                  value={ billingAddress.mobile || '' }
                  onChange={ e => setBillingAddress(prev => ({ ...prev, mobile: e.target.value})) }
                />
              </div>

              <div className="field">
                <div className='flex-box items-center'>
                  <label>
                  { appProps.translations.reservations.address }
                  </label>
                  <div style={{marginLeft: 5}}>*</div>
                </div>
                <input
                  id="billing-street"
                  type="text"
                  value={ billingAddress.address || '' }
                  onChange={ e => setBillingAddress(prev => ({ ...prev, address: e.target.value})) }
                />
              </div>

              <div className="field">
                <div className='flex-box items-center'>
                  <label>
                    { appProps.translations.reservations.city }
                  </label>
                  <div style={{marginLeft: 5}}>*</div>
                </div>
                <input id="billing-city"
                  type="text"
                  value={ billingAddress.city || '' }
                  onChange={ e => setBillingAddress(prev => ({ ...prev, city: e.target.value})) }
                />
              </div>

              <div className="field">
                <div className='flex-box items-center'>
                  <label>
                    { appProps.translations.reservations.postal_code }
                  </label>
                  <div style={{marginLeft: 5}}>*</div>
                </div>
                <input id="billing-postal_code"
                  type="text"
                  value={ billingAddress.postalCode || '' }
                  onChange={ e => setBillingAddress(prev => ({ ...prev, postalCode: e.target.value})) }
                />
              </div>
            </div>

            <div className="field-columns flex-box flex-wrap">
              <div className="field">
                <div className='flex-box items-center'>
                  <label>
                    { appProps.translations.reservations.country }
                  </label>
                  <div style={{marginLeft: 5}}>*</div>
                </div>
                <Select
                  id="billing-country"
                  theme={ customReactSelectTheme }
                  styles={ customReactSelectStyles }
                  isSearchable={ false }
                  options={ availableCountries }
                  value={ selectedCountry }
                  onChange={ (option) => handleCountryChange(option) }
                  onMenuOpen={ () => getAvailableCountries() }
                />
              </div>

              { statesRequired &&
                <div className="field">
                  <div className='flex-box items-center'>
                    <label>
                    { appProps.translations.reservations.state }
                    </label>
                    <div style={{marginLeft: 5}}>*</div>
                  </div>
                  <Select
                    id="billing-area"
                    theme={ customReactSelectTheme }
                    styles={ customReactSelectStyles }
                    isSearchable={ false }
                    options={ availableStates }
                    value={ !selectedState && availableStates.length > 0 && billingAddress.area && billingAddress.area.length > 0 ? availableStates.filter(s => s.value === billingAddress.area) : selectedState }
                    onChange={ (option) => handleStateChange(option) }
                    onMenuOpen={ () => getAvailableStates(selectedCountry.value) }
                  />
                </div>
              }
            </div>
          </div>
        }

        { canIssueInvoice &&
          <Invoice
            invoiceState={ invoiceState }
            setInvoiceState={ setInvoiceState }
            translations={ appProps.translations }
            selectedCountry={ selectedCountry }
            selectedMethod={ selectedMethod }
            clearError={ clearError }
            showInvoiceForm={ showInvoiceForm }
            currentInvoice={ invoice }
            companyCheckout={ false }
          />
        }

        <div className="field accept-terms">
          <div>
            <div className="switch-wrapper">
              <div className="flex-box items-center">
                <label className="switch">
                  <input
                    id="accept-terms"
                    type="checkbox"
                    name="acceptedTerms"
                    value="1"
                    defaultChecked={ acceptedTerms }
                    onChange ={ (e) => setAcceptedTerms(e.target.checked) }
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div
                id="term-text"
                className="switch-text pointer link"
                onClick={ () => setShowTerms(true) }
              >
                { appProps.translations.general.button_accept } { appProps.translations.general.terms_and_conditions }
              </div>
            </div>
          </div>
        </div>

        <div className='flex-box content-end' style={{ marginTop: 40, width: '100%' }}>
          <button onClick={ handleSubmit } className='button'>
            { appProps.translations.reservations.proceed_payment }
          </button>
        </div>
      </div>

      { showTerms &&
        <Modal
          visible
          mode='medium'
          closeModal={ ()=> setShowTerms(false) }
        >
          { legalTerms  && <div dangerouslySetInnerHTML={{__html: legalTerms }} className="text"></div> }
        </Modal>
      }

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}
