import React, { useState, useEffect } from "react"
import ServiceItem from "./ServiceItem"
import Pagination from "./Pagination"
import ListStyles from "./ListStyles"

import { loadServicesListAnalytics } from "../../helpers/analytics"
import { constructServicesListUrl } from "../../helpers/utils"

export default function Products({
  appProps, containerClass, serviceParameters, hideSorting, currency, servicesList
}) {
  const [services, setServices] = useState(servicesList)
  const [serviceParams, setServiceParams] = useState(serviceParameters)

  useEffect(() => {
    loadServicesListAnalytics(services, appProps)
  }, []);

  function getProductRequestParams(page) {
    return {
      order_by: serviceParams.orderBy,
      order: serviceParams.order,
      page: page || serviceParams.page,
      per_page: serviceParams.perPage > 48 ? 48 : serviceParams.perPage
    }
  }

  return (
    <>
      <ListStyles
        servicesPerRow={ appProps.servicesItems }
      />
      <div className='flex-box items-center' style={{ padding: '70px 0px', background: '#f7f7f7' }}>
        <h1 className='main-container'>
          { appProps.translations.services.services }
        </h1>
      </div>
      <div className={ containerClass } style={{ paddingTop: 40, minHeight: '50vh' }}>
        <div className="flex-box flex-wrap services-list">
          { services.length > 0 ?
            services.map(service => {
              return (
                <ServiceItem
                  appProps={ appProps }
                  service={ service }
                  key={ service.id }
                  currency={ currency.iso_code }
                />
              )
            })
            :
            <div><h2>{ appProps.translations.services.no_services_available }</h2></div>
          }
        </div>
        <div className="page-navigation-wrapper">
          <Pagination
            appProps={ appProps }
            serviceParams={ serviceParams }
          />
        </div>
      </div>
    </>
  )
}
