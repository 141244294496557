import React, { useState, useEffect } from 'react'
import ServiceItem from '../../services/ServiceItem'

export function Service({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [services, setServices] = useState([])
  const settings = item.contentSettings

  useEffect( () => {
    if (settings.selectedServices.length) {
      setLoading(true)

      $.ajax({
        type: 'GET',
        url: appProps.selectedServicesPath,
        data: {
          items: JSON.stringify(settings.selectedServices)
        },
        dataType: 'json',
        success: res => {
          setServices(res.items)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  return (
    <div className={`builder-service ${settings.viewType} service-list`}>
      { services.map( (service, index) => (
        <ServiceItem
          appProps={ appProps }
          key={ index }
          service={ service }
        />
      ))}
    </div>
  )
}
