import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Sidebar } from '@reactiveonline/frontend_shared_components'

const accountComponents = {
  profile:      'Profile',
  privacy:      'Privacy',
  reservations: 'Reservations',
  delete:       'Delete'
}

let AccountComponent = () => <div/>

export default function Account({
  appProps, screenClass, pageName, pageTitle, privacyPolicy, listReservationsPath
}) {
  const [subButton, setSubButton] = useState(true)
  const [showSubButton, setShowSubButton] = useState(false)
  const [renderKey, setRenderKey] = useState(0)
  const [casLoading, setCasLoading] = useState(false)

  const sidebarContent = [
    { path: appProps.accountPath, title: appProps.translations.account.manage_account },
    { path: appProps.reservationsPath, title: appProps.translations.account.reservations },
    { path: appProps.privacyPolicyPath, title: appProps.translations.account.privacy_policy },
    { path: appProps.deleteAccountPath, title: appProps.translations.account.delete_account, isDelete: true }
  ]

  useEffect(() => {
    setCasLoading(true)

    Rails.ajax({
      type: 'GET',
      url: '/account_info',
      dataType: 'json',
      success: res => {
        if(res.account) {
          appProps.setCurrentAppProps({
            user: {
              ...appProps.user,
              avatar: res.account.avatarUrl,
              fullname: res.account.fullname,
              email: res.account.email
            },
            setSubButton: setSubButton,
            setShowSubButton: setShowSubButton
          })
        }

        setCasLoading(false)
      },
      error: res => {
        setCasLoading(false)
      }
    })

    AccountComponent = ['profile', 'privacy', 'delete'].includes(pageName) ? lazy( () => import(`@reactiveonline/frontend_shared_components/components/account/screens/${ accountComponents[pageName] }`) ) : lazy( () => import(`./screens/${ accountComponents[pageName] }`) )
    setTimeout( () => setRenderKey(Math.random()), 100)
  }, [])

  return (
    <div className='main-container'>
      <div className={ screenClass }>
        <Sidebar
          appProps={ appProps }
          sidebarContent={ sidebarContent }
        />
        <div className='main-content'>
          <Suspense
            key={ renderKey }
            fallback={ <div/> }
          >
            <AccountComponent
              pageName={ pageName }
              appProps={ appProps }
              privacyPolicy={ privacyPolicy }
              pageTitle={ pageTitle }
              subButton={ subButton }
              setSubButton={ setSubButton }
              showSubButton={ showSubButton }
              setShowSubButton={ setShowSubButton }
              casLoading={ casLoading }
              listReservationsPath={ listReservationsPath }
            />
          </Suspense>
        </div>
      </div>
    </div>
  )
}
