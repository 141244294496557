import React, { useState, useEffect } from 'react'
import ServiceItem from '../../services/ServiceItem'
import ServiceListSlider from './service_list/ServiceListSlider'

export function ServiceList({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [services, setServices] = useState([])
  const settings = item.contentSettings

  useEffect( () => {
    if (settings.selectedServiceItems !== 'custom' || (settings.selectedServiceItems === 'custom' && settings.selectedServices.length)) {
      setLoading(true)

      $.ajax({
        type: 'GET',
        url: appProps.selectedServicesPath,
        data: {
          items: JSON.stringify(settings.selectedServices),
          selected_service_items: settings.selectedServiceItems,
          service_limit: settings.serviceLimit,
          filtering: settings.filtering
        },
        dataType: 'json',
        success: res => {
          setServices(res.items)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  function getServicesPerRowClass() {
    switch (settings.servicesPerRow) {
      case '1':
      case 1:
        return 'one_item'

      case '2':
      case 2:
        return 'two_items'

      case '3':
      case 3:
        return 'three_items'

      case '4':
      case 4:
        return 'four_items'

      case '5':
      case 5:
        return 'five_items'

      case '6':
      case 6:
        return 'six_items'
    }
  }

  return (
    <>
      { settings.viewType !== 'slider' &&
        <div className={`items-list ${settings.viewType} services-list`}>
          { services.map( (service, index) => (
            <ServiceItem
              key={ index }
              appProps={ appProps }
              service={ service }
              servicesPerRowClass={ getServicesPerRowClass() }
            />
          ))}
        </div>
      }

      { settings.viewType === 'slider' &&
        <ServiceListSlider
          appProps={ appProps }
          settings={ settings }
          services={ services }
          servicesPerRowClass={ getServicesPerRowClass() }
        />
      }
    </>
  )
}
