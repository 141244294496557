import React, { useState, useEffect } from "react"
import moment from 'moment'
import dayjs, { Dayjs } from 'dayjs';
import { Loader } from '@reactiveonline/frontend_shared_components'
import MomentUtils from '@date-io/moment'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import { getTranslatableField } from "../../helpers/utils"

export default function CreateReservationForm({
  appProps, selectedService, currentReservation, actionText, disableFormStyles
}){
  const anyoneTranslation = appProps.translations.reservations.anyone
  const initialService = selectedService || (currentReservation && currentReservation.service)
  const initialDate = currentReservation ? dayjs(new Date(currentReservation.reservation_time_from)) : dayjs(new Date)
  const initialTimeSlot = currentReservation ? currentReservation.time_from : null
  const initialEmployee = currentReservation ? currentReservation.employeeId : null

  const [loading, setLoading] = useState(false)
  const [availableEmployees, setAvailableEmployees] = useState([])
  const [availableTimeSlots, setAvailableTimeSlots] = useState([])
  const [availableServices, setAvailableServices] = useState(appProps.stores[0].services)

  const [requestedReservation, setRequestedReservation] = useState({
    date: initialDate,
    timeSlot: initialTimeSlot,
    people: 1,
    employee: initialEmployee,
    store: appProps.stores[0].id,
    service: initialService
  })

  useEffect(() => {
    if (checkInput()){
      const resDate = requestedReservation.date.toDate()

      setLoading(true)
      let fd = new FormData()
      fd.append('store_id', requestedReservation.store)
      fd.append('date', moment(resDate).format())
      fd.append('employee_id', requestedReservation.employee)
      fd.append('service_id', requestedReservation.service.id)

      Rails.ajax({
        type: 'POST',
        url: appProps.getAvailableTimeSlotsPath,
        dataType: 'json',
        data: fd,
        success: res => {
          setLoading(false)
          setAvailableTimeSlots(res.availableTimeSlots)
          if (!Object.keys(res.availableTimeSlots).includes(requestedReservation.timeSlot)){
            setRequestedReservation(prev => ({ ...prev, timeSlot: null }) )
          }
        }
      })
    }
  }, [requestedReservation.service, requestedReservation.store, requestedReservation.employee, requestedReservation.date])

  useEffect(() => {
    if (requestedReservation.service && requestedReservation.store){
      setLoading(true)
      let fd = new FormData()
      fd.append('store_id', requestedReservation.store)
      fd.append('service_id', requestedReservation.service.id)

      Rails.ajax({
        type: 'POST',
        url: appProps.getAvailableEmployeesPath,
        dataType: 'json',
        data: fd,
        success: res => {
          setLoading(false)
          setAvailableEmployees(res.available_employees)
        }
      })
    }
  },[requestedReservation.service, requestedReservation.store])

  function updateSelectedStore(storeId){
    const selectedStore = appProps.stores.find(s => s.id === storeId)
    if (selectedService){
      setRequestedReservation(prev => ({ ...prev, store: storeId }))
    } else {
      const existingService = requestedReservation.service && selectedStore.services.find(s => s.id === requestedReservation.service.id)
      setRequestedReservation(prev => ({ ...prev, store: storeId, service: existingService }))
      setAvailableServices(selectedStore.services)
    }
  }

  function checkInput(){
    return (requestedReservation.date && requestedReservation.employee && requestedReservation.service && requestedReservation.store)
  }

  function handleCreateReservation(){
    const [hours, minutes] = requestedReservation.timeSlot.split(':')
    const resDate = requestedReservation.date.toDate()
    resDate.setHours(hours, minutes, 0)

    const selectedTimeSlotEmployees = availableTimeSlots[requestedReservation.timeSlot]
    setLoading(true)
    let fd = new FormData()
    fd.append('reservation[store_id]', requestedReservation.store)
    fd.append('service_id', requestedReservation.service.id)
    fd.append('available_employees', JSON.stringify(selectedTimeSlotEmployees))
    fd.append('reservation[interval]', requestedReservation.service.intervalTime + requestedReservation.service.processingTime)
    fd.append('reservation[duration]', requestedReservation.service.intervalTime)
    fd.append('reservation[people]', requestedReservation.people)
    fd.append('reservation[status]', 'cart')
    fd.append('reservation[reserved_at]', moment(resDate).format())
    // if (requestedReservation.employee){
    //   fd.append(`reservation[employee_reservations_attributes][0][employee_id]`, requestedReservation.employee)
    // }

    Rails.ajax({
      type: currentReservation ? 'PATCH' : 'POST',
      url: appProps.reservationPath,
      dataType: 'json',
      data: fd,
      success: res => {
        window.location.href = res.redirect_url
      }
    })
  }

  return (
    <>
      <div style={ !disableFormStyles ? { width: '100%', background: '#ffffff', padding: 20 } : { minWidth: '100%' } }
           className={ !disableFormStyles ? 'box-shadow' : '' }>
        <div>
          <div style={{ marginTop: 10 }}>
            <div className="bold">
              { appProps.translations.reservations.date }
            </div>
            <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale={ appProps.currentLocale }>
              <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                <DateCalendar
                  disablePast
                  value={ requestedReservation.date }
                  onChange={ value => setRequestedReservation(prev => ({ ...prev, date: value ? dayjs(value) : null })) }
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

          { requestedReservation.service && requestedReservation.service.availableSeats && requestedReservation.service.availableSeats > 1 &&
            <div style={{ marginTop: 10 }}>
              <div>
                <div className='bold'>
                  { appProps.translations.reservations.people }
                </div>
                <div className="flex-box items-center" style={{ marginTop: 10 }}>
                  <button
                    type="button"
                    className="button btn inverted"
                    onClick={ () => setRequestedReservation(prev => ({ ...prev, people: prev.people > 1 ? prev.people - 1 : 1 })) }
                  >
                    <i className='fa-light fa-minus'/>
                  </button>
                  <button
                    type="button"
                    className="button btn inverted"
                    onClick={ () => setRequestedReservation(prev => ({ ...prev, people: prev.people + 1 })) }
                  >
                    <i className='fa-light fa-plus'/>
                  </button>
                </div>
              </div>
            </div>
          }

          { appProps.stores.length > 1 &&
            <div style={{ marginTop: 10 }}>
              <div className='bold'>
                { appProps.translations.reservations.store }
              </div>
              <div className="flex-box flex-wrap" style={{ marginTop: 10 }}>
                { appProps.stores.map((store, index) =>
                  <div
                    key={index}
                    style={{
                      marginRight: "5px",
                    }}
                    className={`flex-box content-center items-center button ${requestedReservation.store === store.id ? '' : 'inverted'}`}
                    onClick={ () => updateSelectedStore(store.id)}
                  >
                    <div style={{ padding: 10 }}>
                      { store.title }
                    </div>
                  </div>)
                }
              </div>
            </div>
          }

          { !selectedService && availableServices &&
            <div style={{ marginTop: 20 }}>
              <div className='bold'>
                { appProps.translations.services.service }
              </div>
              <div className="flex-box flex-wrap" style={{ marginTop: 10 }}>
                { availableServices.map((service, index) =>
                  <div
                    key={index}
                    style={{
                      position: 'relative',
                      marginRight: 15,
                      border: '1px solid #dddddd',
                      borderRadius: 7,
                      cursor: "pointer"
                    }}
                    className='flex-box content-center items-center'
                    onClick={ () => setRequestedReservation(prev => ({ ...prev, service: service })) }
                  >
                    { requestedReservation.service && requestedReservation.service.id === service.id &&
                      <div className='checked_icon'
                           style={{
                             color: appProps.frontendStyles.general.colorScheme,
                             position: 'absolute',
                             right: 3,
                             top: 3
                           }}
                      />
                    }
                    <div className='flex-box'>
                      { service.images.length > 0 &&
                        <img style={{ width: 50, height: 50, objectFit: 'cover' }} src={ service.images[0].thumbnail } />
                      }
                      <div className='flex-box flex-column' style={{ marginLeft: 10, padding: '0px 30px 0px 0px' }}>
                        <div style={{ fontSize: 14 }} className='bold'>
                          { getTranslatableField(service, 'title', appProps.currentLocale, false) }
                        </div>
                        <div style={{ fontSize: 12 }}>{ service.displayTotalPrice }</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }

          { availableEmployees.length > 0 &&
            <div style={{ marginTop: 20 }}>
              <div className='bold'>
                { appProps.translations.reservations.employee }
              </div>
              <div className="flex-box flex-wrap" style={{ rowGap: 10, marginTop: 20 }}>
                { [ {fullName: anyoneTranslation, id: "anyone"}, ...availableEmployees ].map((employee, index) =>
                  <div
                    key={index}
                    style={{
                      borderRadius: '50%',
                      width: 80,
                      height: 80,
                      marginRight: 10
                    }}
                    className={`centered flex-box content-center items-center flex-column button ${requestedReservation.employee === employee.id ? '' : 'inverted'}`}
                    onClick={ () => setRequestedReservation(prev => ({ ...prev, employee: employee.id}))
                  }
                  >
                    <div>
                    { employee.fullName }
                    </div>
                  </div>)
                }
              </div>
            </div>
          }

          { !checkInput() &&
            <div className="flex-box" style={{ marginTop: 20 }}>
              { appProps.translations.reservations.incomplete_user_input_field_message }
            </div>
          }

          { requestedReservation.employee && Object.keys(availableTimeSlots).length > 0 &&
            <div style={{ marginTop: 20 }}>
              <div className='bold'>
                { appProps.translations.reservations.time_slot }
              </div>
              <div className="flex-box flex-wrap" style={{ marginTop: 10, rowGap: 10 }}>
                { Object.keys(availableTimeSlots).map((timeSlot, index) =>
                  <div
                    key={index}
                    style={{
                      marginRight: "5px",
                    }}
                    className={`flex-box content-center items-center button ${requestedReservation.timeSlot === timeSlot ? '' : 'inverted'}`}
                    onClick={ () => setRequestedReservation(prev => ({ ...prev, timeSlot: timeSlot})) }
                  >
                    <div style={{ padding: '5px 0px' }}>
                      { timeSlot }
                    </div>
                  </div>
                  )
                }
              </div>
            </div>
          }

          { !loading && checkInput() && Object.keys(availableTimeSlots).length === 0 &&
            <div className="flex-box" style={{ marginTop: 20 }}>
              { appProps.translations.reservations.no_available_time_slots }
            </div>
          }

          { requestedReservation.timeSlot &&
            <div style={{ marginTop: 20 }}>
              <button
                type="button"
                style={{ width: "100%" }}
                onClick={ handleCreateReservation }
              >
                { actionText }
              </button>
            </div>
          }
        </div>
      </div>

      { loading &&
        <Loader
          size='large'
          position='center'
          fullPage
        />
      }
    </>
  )
}
