import React, { useState } from "react"
import { AuthorizableLink } from '@reactiveonline/frontend_shared_components'
import MiniCart from "./MiniCart"

export default function HeaderIcons({ appProps, }) {
  const localePrefix = appProps.currentLocale ? `/${appProps.currentLocale}` : ''
  const { translations, registrationEnabled } = appProps

  let loginData = [{ title: translations.account.login_button, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` }]
  if(registrationEnabled) {
    loginData.push({ title: translations.account.register_button, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` })
  }

  return (
    <>
      <div className="header-icons-container">
        <ul>
          { appProps.currentReservationPath &&
            <li className="cart-dropmenu mini_cart">
              <div id="mini-cart-ajax-container">
                <MiniCart
                  appProps={ appProps }
                />
              </div>
            </li>
          }
          <li className="account">
            { appProps.userFormLoginType ?
              <AuthorizableLink
                appProps={ appProps }
                linkClass="pointer account_icon my-account"
                authorizableScreen='login'
                linkRef={ appProps.accountPath }
              /> :
              <a className="pointer account_icon my-account" href={ appProps.accountPath }></a>
            }
          </li>

          <li className="logout">
            { appProps.user &&
              <a href={ appProps.logoutPath } data-method='delete' className="logout_icon"></a>
            }
          </li>
        </ul>
      </div>
    </>
  )
}
