import React, { useState, useEffect } from 'react'
import { getTranslatableField } from "../../helpers/utils"
import Coupons from './Coupons'

export default function Summary({ appProps, currentStep, reservation, setLoading, couponAdjustments, setCouponAdjustments, totalWithAdjustments, setTotalWithAdjustments }){
  function handleDestroy(){
    setLoading(true)
    Rails.ajax({
      type: 'DELETE',
      url: appProps.reservationPath,
      dataType: 'json',
      success: res => {
        window.location.href = res.redirectTo
      }
    })
  }

  if(!reservation){
    return (
      <div className='summary-container card'>
        <div className='summary-details'>
          <div className='summary-detail heading'>
            <h3>
              { appProps.translations.reservation_summary.title }
            </h3>
          </div>
          <div className='summary-detail'>
            { appProps.translations.reservation_summary.no_service_selected }
          </div>
        </div>
      </div>
    )
  }

  const { images = [] } = reservation.service
  const serviceTitle = getTranslatableField(reservation.service, 'title', appProps.currentLocale, false)

  return(
    <div className='summary-container card'>
      <div className='top-section'>
        <div className='summary-details'>
          <div className='summary-detail heading'>
            <h3>
              { appProps.translations.reservation_summary.title }
            </h3>
          </div>
          { images.length > 0 &&
            <div className='summary-detail image'>
              <img src={ images[0]?.thumbnail } alt='thumbnail'/>
            </div>
          }
        </div>
      </div>

      <div className='bottom-section'>
        <div className='service-info'>
          <div className='summary-details'>
            <div className='summary-detail name'>
              { serviceTitle }
            </div>
            <div className='summary-detail'>
              <div className='label'>
                { appProps.translations.reservation_summary.store }
              </div>
              <div className='value'>{ reservation.storeAddress }</div>
            </div>
            <div className='summary-detail'>
              <div className='label'>
                { appProps.translations.reservation_summary.employees }
              </div>
              <div className='value'>{ reservation.employees }</div>
            </div>
            <div className='summary-detail'>
              <div className='label'>
                { appProps.translations.reservation_summary.date }
              </div>
              <div className='value'>{ reservation.date_from }</div>
            </div>
            <div className='summary-detail'>
              <div className='label'>
                { appProps.translations.reservation_summary.start_time }
              </div>
              <div className='value'>{ reservation.time_from }</div>
            </div>
            <div className='summary-detail'>
              <div className='label'>
                { appProps.translations.reservation_summary.duration }
              </div>
              <div className='value'>{ reservation.duration }</div>
            </div>
          </div>

          <div className='flex-box content-end'>
            <button onClick={ handleDestroy } className='button'>
              { appProps.translations.reservations.delete_reservation }
            </button>
          </div>
        </div>

        <div>
          <div className='service-info'>
            <div className='summary-details'>
              <div className='summary-detail name'>
                { appProps.translations.reservation_summary.cost }
              </div>
              <div className='summary-detail'>
                <div className='label'>
                  { appProps.translations.reservation_summary.subtotal }
                </div>
                <div className='value'>{ reservation.displaySubtotal }</div>
              </div>
              <div className='summary-detail'>
                <div className='label'>
                  { appProps.translations.reservation_summary.vat }
                </div>
                <div className='value'>{ reservation.displayVatTotal }</div>
              </div>
              <div className='summary-detail'>
                <div className='label'>
                  { appProps.translations.reservation_summary.total }
                </div>
                <div className='value'>{ reservation.displayTotal }</div>
              </div>
            </div>
          </div>
          { currentStep === 2 &&
            <Coupons
              appProps={ appProps }
              couponAdjustments={ couponAdjustments }
              setCouponAdjustments={ setCouponAdjustments }
              reservationTotal={ totalWithAdjustments }
              setTotalWithAdjustments={ setTotalWithAdjustments }
              translations={ appProps.translations }
              setLoading={ setLoading }
              reservationId={ reservation.id }
            />
          }
        </div>
      </div>

    </div>
  )
}
