import React, { useState, useEffect } from 'react'
import CreateReservationForm from '../../shared/CreateReservationForm'

export function BookingWidget({ appProps, item }) {
  const settings = item.contentSettings

  function headerElement() {
    switch (settings.headerSize) {
      case ('h1'):
        return <h1 style={ settings.headerStyles }>{ settings.headerText }</h1>
      case ('h2'):
        return <h2 style={ settings.headerStyles }>{ settings.headerText }</h2>
      case ('h3'):
        return <h3 style={ settings.headerStyles }>{ settings.headerText }</h3>
      case ('h4'):
        return <h4 style={ settings.headerStyles }>{ settings.headerText }</h4>
      case ('h5'):
        return <h5 style={ settings.headerStyles }>{ settings.headerText }</h5>
      case ('h6'):
        return <h6 style={ settings.headerStyles }>{ settings.headerText }</h6>
    }
  }

  return (
    <div style={ {...settings.styles, ...settings.headerStyles} }>
      { headerElement() }

      <CreateReservationForm
        appProps={ appProps }
        actionText={ appProps.translations.reservations.create }
        disableFormStyles
      />
    </div>
  )
}
