import { Service } from './elements/Service'
import { ServiceList } from './elements/ServiceList'
import { BookingWidget } from './elements/BookingWidget'

export function additionalElements(props) {
  return {
    service: {
      component: Service,
      props: props
    },
    service_list: {
      component: ServiceList,
      props: props
    },
    booking_widget: {
      component: BookingWidget,
      props: props
    }
  }
}


