import React from 'react'

function rangeNumbers(from, to) {
  let result = []
  for (let i = from; i <= to; i++) {
    result.push(i)
  }
  return result
}

export function constructServicesListUrl(params, locale = null) {
  const localePrefix = locale ? `/${locale}` : ''
  let url = `${localePrefix}/services`
  return url
}

export function getTranslatableField(entity, field, currentLocale, fallback = false) {
  if(entity.translations) {
    let currentTranslatableFields
    if(currentLocale) {
      currentTranslatableFields = entity.translations.filter(t => t.locale === currentLocale)[0]
    } else {
      currentTranslatableFields = entity.translations[0]
    }

    const firstTranslatableFields = entity.translations.filter(t => t[field])[0]

    if(!fallback) {
      if(currentTranslatableFields) {
        return currentTranslatableFields[field] || ''
      } else {
        return ''
      }
    } else {
      if(currentTranslatableFields && currentTranslatableFields[field]) {
        return currentTranslatableFields[field] || ''
      } else if(firstTranslatableFields) {
        return firstTranslatableFields[field] || ''
      }
    }
  }

  return ''
}

export function formatPriceWithCurrency(amount, currency) {
  if(currency && currency.symbol_first) {
    return `${currency.symbol}${amount}`
  } else if(currency) {
    return `${amount} ${currency.symbol}`
  } else {
    return `€${amount}`
  }
}

export function constructTranslatableProductLink(entity, currentLocale, localeUrlPresent) {
  return `${localeUrlPresent ? '/' + currentLocale : ''}/services/${getTranslatableField(entity, 'slug', currentLocale, false)}`
}

export function stringTimeConvert(string) {
  let output = string.match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [string];

  if (output.length > 1) {
    output = output.slice (1)
    output[5] = +output[0] < 12 ? ` ${ Appointment.translations.general.time_am }` : ` ${ Appointment.translations.general.time_pm }`
    output[0] = +output[0] % 12 || 12
    output[0] = output[0] < 10 ? `0${ output[0] }` : output[0]
  }
  return output.join('');
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

export function numberValidator(value) {
  const regex = /^[0-9()+-]+$/g
  return value.match(regex)
}

export const customReactSelectStyles = {
  option: (provided, state) => {
    let color = '#222222'
    let background = '#ffffff'
    if(state.isSelected) {
      color = '#ffffff'
      background = '#666666'
    } else if(state.isFocused) {
      background = '#f7f7f7'
    }
    return {
      ...provided,
      color: color,
      background: background
    }
  },
  control: (provided, state) => ({
    ...provided,
    height: 45,
    minHeight: 45,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: state.isFocused ? 0 : provided.boxShadow,
    '&:hover': {
       border: '1px solid hsl(0,0%,80%)'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 45
  })
}

export function customReactSelectTheme(theme) {
  return {
    ...theme,
    colors: {
    ...theme.colors,
      primary50: '#f7f7f7'
    }
  }
}