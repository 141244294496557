import React, { useState, useEffect, useRef } from "react"
import { BankDetails } from '@reactiveonline/frontend_shared_components'

export default function ReservationItem({ appProps, reservation, getReservationPath }) {
  const [reservationItem, setReservationItem] = useState(reservation)
  const [showReservation, setShowReservation] = useState(false)
  const [loading, setLoading]                 = useState(false)

  useEffect(() => {
    if(showReservation) {
      fetchOrder(reservationItem.getReservationPath)
    }
  }, [showReservation])

  function fetchOrder(getReservationPath) {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: getReservationPath,
      dataType: "json",
      success: res => {
        if(res.reservation) {
          setReservationItem(res.reservation)
        }
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className={`history_rows pointer ${showReservation ? 'active' : ''}`} onClick={ () => setShowReservation(prevState => !prevState) } >
        <div className="row">
          { reservationItem.date }
        </div>
        <div className="row">
          { reservationItem.number }
        </div>
        <div className="row">
          { appProps.translations.statuses[reservationItem.status] }
        </div>
        <div className="row">
          { reservationItem.totalWithAdjustments }
        </div>
        <div className="row content-end">
          <div className={`view ${showReservation ? 'up_icon' : 'down_icon'}`} />
        </div>
      </div>

      { showReservation && reservationItem &&
        <div className="order-info-wrapper">
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.number }</div>
            <div className='bold'>{ reservationItem.number }</div>
          </div>
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.sellable }</div>
            <div className='bold'>{ reservationItem.sellable }</div>
          </div>
          { reservationItem.store_address &&
            <div className='order-info flex-box items-center content-space-between'>
              <div className='flex-1'>{ appProps.translations.reservations.store_address }</div>
              <div className='bold'>
                <a className='flex-box items-center' href={`https://www.google.com/maps/search/${reservationItem.store_address}`} target="_blank">
                  <i className='fa-light fa-location-pin' style={{ marginRight: 5 }} />
                  <div>{ reservationItem.store_address }</div>
                </a>
              </div>
            </div>
          }
          { reservationItem.employees && reservationItem.employees.length > 0 &&
            <div className='order-info flex-box items-center content-space-between'>
              <div className='flex-1'>{ appProps.translations.reservations.employees }</div>
              <div className='bold'>{ reservationItem.employees }</div>
            </div>
          }
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.status }</div>
            <div className='bold'>{ appProps.translations.statuses[reservationItem.status] }</div>
          </div>
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.email }</div>
            <div className='bold'>{ reservationItem.email }</div>
          </div>
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.people }</div>
            <div className='bold'>{ reservationItem.people }</div>
          </div>
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.date }</div>
            <div className='bold'>{ reservationItem.date }</div>
          </div>
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservation_summary.payment_method }</div>
            <div className='bold'>{ reservationItem.paymentMethod }</div>
          </div>
          { reservationItem.bankDetails && !!reservationItem.bankDetails.length &&
            <div style={{ marginTop: 20 }}>
              <BankDetails
                appProps={ appProps }
                bankDetails={ reservationItem.bankDetails }
              />
            </div>
          }
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.duration }</div>
            <div className='bold'>{ reservationItem.duration }</div>
          </div>
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.net_total }</div>
            <div className='bold'>{ reservationItem.subtotal }</div>
          </div>
          <div className='order-info flex-box items-center content-space-between'>
            <div className='flex-1'>{ appProps.translations.reservations.tax }</div>
            <div className='bold'>{ reservationItem.tax }</div>
          </div>

          { reservationItem.couponAdjustments && reservationItem.couponAdjustments.length > 0 ?
            <>
              <div className="order-info">
                <div className="flex-box items-center content-space-between">
                  { appProps.translations.reservations.coupons_title }:
                </div>

                <div>
                  { reservationItem.couponAdjustments.map( couponAdjustment => (
                    <div className="flex-box items-center content-space-between" key={ couponAdjustment.orderAdjustmentId }>
                      <div className='flex-1'>
                        { couponAdjustment.code }
                      </div>
                      <div className='bold'>
                        -{ couponAdjustment.discount }
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="order-info">
                <div className="flex-box items-center content-space-between" >
                  <div className='flex-1'>{ appProps.translations.reservation_summary.total }:</div>
                  <div className='bold'>{ reservationItem.totalWithAdjustments }</div>
                </div>
              </div>
          </>
          :
            <div className="order-info flex-box items-center content-space-between">
              <div className='flex-1'>{ appProps.translations.reservation_summary.total }:</div>
              <div className='bold'>{ reservationItem.displayTotal }</div>
            </div>
          }
          { reservationItem.notes &&
            <div className='order-info flex-box items-center content-space-between'>
              <div className='flex-1'>{ appProps.translations.reservations.notes }</div>
              <div dangerouslySetInnerHTML={{ __html: reservationItem.notes }} />
            </div>
          }
        </div>
      }
    </>
  )
}
