import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import ReservationItem from "../shared/ReservationItem"

export default function Reservations({
  appProps, pageName, pageTitle, listReservationsPath
}) {
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [reservations, setReservations] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkFetched, setCheckFetched] = useState(false)

  useEffect( () => {
    fetchReservations(1)
  }, [])

  function fetchReservations(page) {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: `${listReservationsPath}?page=${page}`,
      dataType: "json",
      success: res => {
        setPage(page)
        setTotalPages(res.totalPages)
        setReservations([...reservations, ...res.reservations])
        setLoading(false)
        setCheckFetched(true)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function renderReservations() {
    return (
      <>
        <div className="heading">
          <h3>{appProps.translations.account.order_history}</h3>
          <h5>{appProps.translations.account.order_history_subtitle}</h5>
        </div>

        <div className="order_history_wrapper">
          <div className="history_headers">
            <div className="row bold">
              { appProps.translations.reservations.date }
            </div>
            <div className="row bold">
              { appProps.translations.reservations.number }
            </div>
            <div className="row bold">
              { appProps.translations.reservations.status }
            </div>
            <div className="row bold">
              { appProps.translations.reservations.total }
            </div>
            <div className="row actions">
              {/* Rendering view icon in this column without heading */}
            </div>
          </div>

          { reservations.map((reservation, index) => (
            <ReservationItem
              key={index}
              reservation={ reservation }
              appProps={ appProps }
            />
          ))}

          { page < totalPages &&
            <div className="flex-box item-center content-center" style={{marginTop: 40}}>
              <button className="button" onClick={() => {
                fetchReservations(page + 1)
              }}>Load More</button>
            </div>
          }
        </div>
      </>
    )
  }

  let noReservationsView = null
  if(checkFetched) {
    noReservationsView =
      <div className="no_data orders_icon flex-box items-center content-center flex-column">
        <h2>
          { appProps.translations.reservations.no_reservations_message }
        </h2>
      </div>
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      <div className='card'>
        { reservations.length > 0 ? renderReservations() : noReservationsView }

        { loading && <Loader size='medium' position='center' /> }
      </div>
    </>
  )
}
