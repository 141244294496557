import React, { useState } from "react"
import ReservationCancel from "../account/shared/ReservationCancel"
import { getTranslatableField } from "../../helpers/utils"
import { BankDetails } from "@reactiveonline/frontend_shared_components"

export default function ReservationInformation({ appProps, reservation, paymentMethodTitle, contactDetails, billingAddress, cancelReservationPath, bankDetails }) {
  const { service } = reservation
  const serviceDescription = getTranslatableField(service, 'description', appProps.currentLocale, false)

  function printOrder() {
    window.print();
    return false;
  }

  return(
    <>
      <div className='main-container'>
        <div className="flex-box flex-wrap">

          <div className='booking card flex-box flex-column'>
            <div style={{ marginBottom: 20 }}>
              <b>{ appProps.translations.reservations.reservation_contents }</b>
            </div>

            { service.images.length > 0 &&
              <div className='booking-image'>
                <img src={ service.images[0]?.preview } alt={ service.title } />
              </div>
            }

            <div className='booking-details'>
              <div className='booking-detail name'>
                <h3><b>{ getTranslatableField(service, 'title', appProps.currentLocale, false) }</b></h3>
              </div>

              { serviceDescription && serviceDescription != '<p></p>' &&
                <div className='booking-detail description' dangerouslySetInnerHTML={{__html: serviceDescription}}></div>
              }

              <div className='booking-detail arrangements'>
                <div>
                  <b>{ appProps.translations.reservation_summary.employees }</b>
                </div>
                <div className="details-list">
                  <div className='detail'>
                    { reservation.employees }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='order-details-wrapper'>
            <div className="order-details flex-box flex-column">
              <div className="order-detail-block card">
                <div className="order-detail-block-title">
                  { appProps.translations.reservations.reservation_number }: { reservation.number }
                </div>
                <div className="order-detail-block-description">
                  { appProps.translations.reservation_summary.store }: { reservation.storeAddress } <br/>
                  { appProps.translations.reservation_summary.date }: { reservation.date_from } <br/>
                  { appProps.translations.reservation_summary.start_time }: { reservation.time_from } <br/>
                  { appProps.translations.reservation_summary.duration }: { reservation.duration }
                </div>
              </div>

              <div className="order-detail-block card">
                <div className="order-detail-block-title flex-box items-center content-space-between">
                  <div>
                    { appProps.translations.reservations.payment_status }:
                  </div>
                  <div>
                    { reservation.paymentState ? appProps.translations.reservations[`payment_state_${reservation.paymentState}`] : '-' }
                  </div>
                </div>
                <div className="order-detail-block-description">
                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.reservation_summary.subtotal }</div>
                    <div>{ reservation.displaySubtotal }</div>
                  </div>

                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.reservation_summary.vat }</div>
                    <div>{ reservation.displayVatTotal }</div>
                  </div>

                  { reservation.couponAdjustments.length > 0 ?
                    <>
                      <div className="flex-box items-center content-space-between">
                        { appProps.translations.reservations.coupons_title }:
                      </div>

                      <div style={{ marginBottom: 5 }}>
                        { reservation.couponAdjustments.map( couponAdjustment => (
                          <div className="flex-box items-center content-space-between" key={ couponAdjustment.orderAdjustmentId }>
                            <div>
                              { couponAdjustment.code }
                            </div>
                            <div>
                              -{ couponAdjustment.discount }
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="flex-box items-center content-space-between" style={{ marginTop: 10 }}>
                        <div>{ appProps.translations.reservation_summary.total }:</div>
                        <div>{ reservation.totalWithAdjustments }</div>
                      </div>
                    </>
                  :
                    <div className="flex-box items-center content-space-between" style={{ marginTop: 20 }}>
                      <div>{ appProps.translations.reservation_summary.total }:</div>
                      <div>{ reservation.displayTotal }</div>
                    </div>
                  }
                </div>
              </div>

              <div className="order-detail-block card">
                <div className="order-detail-block-title">
                  { appProps.translations.reservations.contact_details }
                </div>
                <div className="order-detail-block-description">
                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.reservations.email }:</div>
                    <div>{ contactDetails.email }</div>
                  </div>

                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.reservations.full_name }:</div>
                    <div>{ contactDetails.full_name }</div>
                  </div>

                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.reservations.phone }:</div>
                    <div>{ contactDetails.phone }</div>
                  </div>

                  { contactDetails.mobile_phone &&
                    <div className='flex-box items-center content-space-between'>
                      <div>{ appProps.translations.reservations.mobile }:</div>
                      <div>{ contactDetails.mobile_phone }</div>
                    </div>
                  }
                </div>
              </div>

              <div className="order-detail-block card">
                <div className="order-detail-block-title">
                  { appProps.translations.reservations.billing_info }
                </div>
                <div className="order-detail-block-description">
                  { billingAddress }
                </div>

                { (reservation.status === 'booked' || reservation.status === 'complete') &&
                  <>
                    <div className="order-detail-block-description flex-box items-center content-space-between" style={{ margin: '20px 0px' }}>
                      <div>
                        { appProps.translations.reservation_summary.payment_method }:
                      </div>
                      <div>
                        { paymentMethodTitle }
                      </div>
                    </div>
                    { !!bankDetails.length &&
                      <BankDetails
                        appProps={ appProps }
                        bankDetails={ bankDetails }
                      />
                    }
                  </>
                }
              </div>

              { reservation.notes &&
                <div className="order-detail-block card">
                  <div className="order-detail-block-title">
                    { appProps.translations.reservations.reservation_notes }
                  </div>
                  <div className="order-detail-block-description">
                    { reservation.notes }
                  </div>
                </div>
              }

            </div>
          </div>
        </div>

      </div>

      { reservation.invoice &&
        <div className='flex-box flex-column card'>
          <div className='bold' style={{ fontSize: 17, marginBottom: 20 }}>
            { appProps.translations.invoices.invoice }
          </div>

          <div className='detail-item flex-box flex-column'>
            <div className="flex-1">
              { appProps.translations.invoices.companyName }
            </div>
            <div className="bold">
              { reservation.invoice.company_name }
            </div>
          </div>

          { reservation.invoice.vat_number &&
            <div className='detail-item flex-box flex-column'>
              <div className='flex-1'>
                { appProps.translations.invoices.vatNumber }
              </div>
              <div className="bold">
                { reservation.invoice.vat_number }
              </div>
            </div>
          }

          { billingAddress &&
            <div className='detail-item flex-box flex-column'>
              <div className='flex-1'>
                { appProps.translations.company.billing_address }
              </div>
              <div className="bold">
                { billingAddress }
              </div>
            </div>
          }

          { reservation.invoice.additional_info &&
            <div className='detail-item flex-box flex-column'>
              <div className="flex-1">
                { appProps.translations.invoices.additionalInfo }
              </div>
              <div className="bold">
                <div dangerouslySetInnerHTML={{ __html: reservation.invoice.additional_info.replace(/\n/g, "<br>") }} />
              </div>
            </div>
          }
        </div>
      }

      <div className='main-container'>
        <div className="flex-box content-space-between">
          <div className="flex-box flex-column items-start flex-1 card">
            <h3><b>{ appProps.translations.reservations.print_reservation }</b></h3>
            <div>{ appProps.translations.reservations.print_reservation_text }</div>
            <div className='button inverted pointer' style={{ width: 'auto', marginTop: 20 }} onClick={ ()=> printOrder() }>
              { appProps.translations.reservations.print_reservation }
            </div>
          </div>
          {/* <div className="flex-1 card">
            <ReservationCancel
              appProps={ appProps }
              reservationStatus={ reservation.status }
              reservationStatusTranslation={ reservation.status }
              cancelText={ appProps.translations.general.cancel }
              confirmText={ "OK" }
              confirmationDialog={ appProps.translations.reservations.cancel_reservation_confirm }
              cancelReservationPath={ cancelReservationPath }
            />
          </div> */}
        </div>
      </div>
    </>
  )
}
