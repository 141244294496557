import React, { useState, useEffect, useCallback } from "react"
import { Loader } from '@reactiveonline/frontend_shared_components'
import { SliderImages } from '@reactiveonline/frontend_shared_components'
import { getTranslatableField, constructTranslatableProductLink } from "../../helpers/utils"

export default function ServiceItem({
  appProps, service, currency, isOnSlider, servicesPerRowClass
}) {
  const {
    translations, darkBackground, itemBorder, itemShadow,
    serviceItems, currentLocale, defaultLocale, localeUrlPresent
  } = appProps

  const serviceImages = service.images.length > 0 ? service.images.map( image => {
    return {
      original: image.list_view,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: '',
    fullscreen: ''
  }]

  return (
    <>
      <div className={`service-item ${ servicesPerRowClass || '' }`}>
        <div className='overlay' />
        <div className="service-image" style={{ position: 'relative' }}>
          <a href={ constructTranslatableProductLink(service, currentLocale, defaultLocale, localeUrlPresent) } rel="nofollow noindex" >
            <SliderImages
              images={ serviceImages }
              showThumbnails={ false }
              showFullscreenButton={ false }
              showNav={ false }
              showBullets={ false }
              lightbox={ false }
              thumbnailPosition={ 'bottom' }
            />
          </a>
        </div>
        <a className="title flex-box flex-column items-center content-center" href={ constructTranslatableProductLink(service, currentLocale, defaultLocale, localeUrlPresent) } rel="nofollow noindex" >
          { getTranslatableField(service, 'title', currentLocale, false) }
        </a>
      </div>
    </>
  )
}
