import React from 'react'
import CreateReservationForm from "../shared/CreateReservationForm"
import { getTranslatableField } from "../../helpers/utils"

export default function SelectReservationStep({
  appProps, currentStep, reservation, onSetCurrent, currentLocale
}){

  const serviceTitle = reservation && getTranslatableField(reservation.service, 'title', currentLocale, false)

  if (currentStep > 0) {
    return(
      <div className='card checkout-selected-step flex-box items-center checked_icon' onClick={ () => onSetCurrent(0) }>
        <div className='edit_icon flex-box items-center content-center'/>
        <div className='flex-box flex-column items-start content-center'>
          <div className='flex-box'>
            { serviceTitle }
          </div>
          <div>
            <b>{ reservation.date_time_from }</b>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className="card checkout-step flex-box content-center flex-column">
      <h3><b>{ reservation ? appProps.translations.reservations.update_reservation : appProps.translations.reservations.create }</b></h3>
      <CreateReservationForm
        appProps={ appProps }
        disableFormStyles
        currentReservation={ reservation }
        actionText={ reservation ? appProps.translations.reservations.update_reservation : appProps.translations.reservations.create }
      />
    </div>
  )
}
