import React, { useState, useEffect, useRef } from 'react'
import { loader } from './helpers/subComponents'
import ErrorMessage from '../shared/ErrorMessage'
import { validateEmail, validatePhoneNumber } from '@reactiveonline/frontend_shared_components/utils'

export default function DetailsStep({ appProps, currentStep, reservationAddress, userEmail, userAddress, onSetCurrent, onNextStep, setLoading, associableAddressId }){
  const reservationAddressId = reservationAddress && reservationAddress.id
  const initialAddress = reservationAddress || userAddress
  const [email, setEmail] = useState(initialAddress ? initialAddress.email : (userEmail || ''))
  const [phone, setPhone] = useState(initialAddress ? initialAddress.phone : '')
  const [mobilePhone, setMobilePhone] = useState(initialAddress ? initialAddress.mobile_phone : '')
  const [fullName, setFullName] = useState(initialAddress ? initialAddress.full_name : '')

  if (currentStep > 1){
    return(
      <div className='card checkout-selected-step flex-box items-center checked_icon' onClick={ () => onSetCurrent(1) }>
        <div className='edit_icon'></div>
        <div className='booking-details'>
          <div className='details'>
            <div className='detail'>
              <b>
                { `${ appProps.translations.reservations.full_name }: ${ initialAddress.full_name }` }
              </b>
            </div>
            <div className='detail'>
              { `${ appProps.translations.reservations.email }: ${ email }` }
              </div>
            <div className='detail'>
              { `${ appProps.translations.reservations.phone }: ${ initialAddress.phone || initialAddress.mobile_phone }` }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const clearErrors = () => {
    document.querySelectorAll('[id$="-error"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')
  }

  const handleDetailsFormSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    clearErrors();

    let noErrors = true;

    if ( email.length === 0 || !validateEmail(email)) {
      ErrorMessage("reservation-email", "red", "reservation-email-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( fullName.length === 0 ) {
      ErrorMessage("reservation-full-name", "red", "billing-full-name-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( !validatePhoneNumber(phone) && !validatePhoneNumber(mobilePhone) ) {
      ErrorMessage("reservation-phone", "red", "billing-phone-error", `${appProps.translations.flash_messages.phone_invalid}`, "red")
      noErrors = false
    }

    if (noErrors) {
      let fd = new FormData()

      fd.append('reservation[associable_address_attributes][address_attributes][full_name]', fullName)
      fd.append('reservation[associable_address_attributes][address_attributes][email]', email)

      if (phone) {
        fd.append('reservation[associable_address_attributes][address_attributes][phone]', phone)
      }
      
      if (mobilePhone) {
        fd.append('reservation[associable_address_attributes][address_attributes][mobile_phone]', mobilePhone)
      }

      if (reservationAddressId) {
        if (associableAddressId){
          fd.append('reservation[associable_address_attributes][id]', associableAddressId)
        }
        fd.append('reservation[associable_address_attributes][address_id]', reservationAddressId)
        fd.append('reservation[associable_address_attributes][address_attributes][id]', reservationAddressId)
      } else if (userAddress) {
        fd.append('reservation[associable_address_attributes][address_id]', userAddress.id)
        fd.append('reservation[associable_address_attributes][address_attributes][id]', userAddress.id)
      }

      Rails.ajax({
        url: appProps.reservationPath,
        type: 'PATCH',
        contentType: 'application/json',
        dataType: 'json',
        data: fd,
        success: res => {
          onNextStep()
        },
        error: res => {
          setLoading(false)
        },
      })
    } else {
      setLoading(false)
    }
  }

  return(
    <div className="card checkout-step flex-box flex-column">
      <h3><b>{ appProps.translations.reservations.contact_details }</b></h3>
      <div className='step-editor flex-box flex-wrap'>
        <form>
          <div className="field">
            <div className='flex-box items-center'>
              <label>
                { appProps.translations.reservations.email }
              </label>
              <div style={{marginRight: '5px'}}>*</div>
            </div>
              <input
                minLength={6}
                name='reservation[email]'
                id='reservation-email'
                // pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                placeholder='johndoe@example.com'
                disabled={!!userEmail}
                type='email'
                defaultValue={userEmail || email}
                onChange={ event => setEmail(event.target.value) }
              />
          </div>

          <div className="field">
            <div className='flex-box items-center'>
              <label>
                { appProps.translations.reservations.full_name }
              </label>
              <div style={{marginRight: '5px'}}>*</div>
            </div>

            <input
              name='reservation[address_attributes][full_name]'
              placeholder='John Doe'
              id='reservation-full-name'
              type='text'
              defaultValue={fullName}
              onChange={ event => setFullName(event.target.value) }
            />
          </div>

          <div className="field">
            <div className='flex-box items-center'>
              <label>
                { appProps.translations.reservations.phone }
              </label>
              <div style={{marginRight: '5px'}}>*</div>
            </div>

            <input
              name='reservation[address_attributes][phone]'
              id='reservation-phone'
              placeholder='+30 123 456 7890'
              type="tel"
              value={ phone || '' }
              onChange={ event => setPhone(event.target.value.match(/^(\+?\d{0,20})?$/) ? event.target.value : phone) }
            />
          </div>

          <div className="field">
            <label>
              { appProps.translations.reservations.mobile }
            </label>
            <input
              name='reservation[address_attributes][mobile_phone]'
              id='reservation-mobile_phone'
              placeholder='+30 123 456 7890'
              type="tel"
              value={ mobilePhone || '' }
              onChange={ event => setMobilePhone(event.target.value.match(/^(\+?\d{0,20})?$/) ? event.target.value : mobilePhone) }
            />
          </div>

          <div className='flex-box content-end' style={{ marginTop: 40, width: '100%' }}>
            <button className='button' onClick={ handleDetailsFormSubmit }>
              { appProps.translations.reservations.next_step }
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
