import React from 'react'
import ServiceItem from '../../../services/ServiceItem'

import SwiperCore, {
  Navigation, Pagination, Scrollbar, A11y, Thumbs, Lazy, Zoom, Autoplay
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([
  Navigation, Pagination, Scrollbar, A11y, Thumbs, Lazy, Zoom, Autoplay
])

export default function ServiceListSlider({ appProps, settings, services, servicesPerRowClass }) {
  const { servicesPerRow, sliderSettings } = settings
  const { arrows, dots, scrollbar, loop, autoMove, moveDuration } = sliderSettings

  return (
    <Swiper
      effect='slide'
      navigation={ arrows }
      pagination={ dots && { clickable: true } }
      scrollbar={ scrollbar && { draggable: true } }
      loop={ loop }
      autoplay={ autoMove && moveDuration && { delay: moveDuration * 1000 } }
      slidesPerView={ servicesPerRow }
      spaceBetween={ 10 }
      style={{ height: '100%', width: '100%' }}
      breakpoints={{
        0: { // when window width is >= 640px
          slidesPerView: 1
        },
        500: { // when window width is >= 500px
          slidesPerView: servicesPerRow == 1 ? 1 : 2
        },
        640: { // when window width is >= 640px
          slidesPerView: servicesPerRow == 1 ? 1 : 3
        },
        768: { // when window width is >= 768px
          slidesPerView: servicesPerRow == 1 ? 1 : (servicesPerRow == (6 || 5 || 4) ? 3 : servicesPerRow)
        },
        900: { // when window width is >= 900px
          slidesPerView: servicesPerRow == 1 ? 1 : (servicesPerRow == (6 || 5) ? 4 : servicesPerRow)
        },
        1080: { // when window width is >= 1080px
          slidesPerView: servicesPerRow == 1 ? 1 : (servicesPerRow == (6 || 5) ? 5 : servicesPerRow)
        },
        1280: { // when window width is >= 1280px
          slidesPerView: servicesPerRow == 1 ? 1 : servicesPerRow
        }
      }}
    >
      { services.map( service => (
        <SwiperSlide
          key={ service.id }
          className={ servicesPerRowClass }
        >
          <ServiceItem
            appProps={ appProps }
            service={ service }
            isOnSlider={ true }
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
