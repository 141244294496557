import React, { Fragment } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'

export const loader = <Fragment>
                        <div className="button place-order">
                          <div className="spinner-wrapper flex-box items-center content-center checkout">
                            <div className="spinner">
                              <div className="double-bounce1"></div>
                              <div className="double-bounce2"></div>
                            </div>
                          </div>
                        </div>
                      <Loader fullPage={ true }/>
                    </Fragment>

export const paymentIFrame = <div className="payment-iframe-overlay">
  <iframe id="payment-iframe" src="" width="500" height="500"> </iframe>
</div>
