import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga4"
import cookie from "react-cookies"
import { getTranslatableField } from "./utils"

const marketingCookies  = cookie.load("cookieSettings") && cookie.load("cookieSettings").marketingCookies === true
const statisticsCookies = cookie.load("cookieSettings") && cookie.load("cookieSettings").statisticsCookies === true

export function loadServicesListAnalytics(services, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('ViewContent', {
      name: [ `${ services.map((service) => `${service.title}`)}`],
    })
  }
}

export function loadServiceViewAnalytics(service, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('ViewContent', {
      name:     getTranslatableField(service, 'title', appProps.currentLocale, false),
      value:    service.price,
      currency: appProps.currency
    })
  }
}
