import React, { useState, useEffect } from "react"
import { Loader } from "@reactiveonline/frontend_shared_components"
import PaymentStep from './PaymentStep'
import SelectReservationStep from './SelectReservationStep'
import DetailsStep from './DetailsStep'
import Summary from './Summary'

export default function Reservations({
  appProps, disabledCheckout, currentReservation, stripePublishableKey, orderId, type, paymentIframePath, cards, setCurrentCardPath, cardsPath, addNewCardText,
  canIssueInvoice, userAddress, legalTerms
}){
  let addressFallbackProps = {
    address:    "",
    city:       "",
    country:    "",
    area:       "",
    mobile:     "",
    fullName:   "",
    phone:      "",
    postalCode: ""
  }

  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(currentReservation ? 1 : 0)
  const [reservation, setReservation] = useState(currentReservation)
  const [showInvoiceForm, setShowInvoiceForm] = useState(false)
  const [couponAdjustments, setCouponAdjustments] = useState(currentReservation ? currentReservation.couponAdjustments: [])
  const [totalWithAdjustments, setTotalWithAdjustments] = useState(currentReservation ? currentReservation.totalWithAdjustments : 0)

  let billingProps
  if (currentReservation && currentReservation.billingAddress && Object.keys(currentReservation.billingAddress).length > 0){
    billingProps = { ...currentReservation.billingAddress }
  } else {
    billingProps = addressFallbackProps
  }

  const [billingAddress, setBillingAddress] = useState({
    vatNumber: '',
    companyName: '',
    additionalInfo: '',
    ...billingProps
  })

  function onSetCurrent(step){
    setCurrentStep(step)
  }

  function onNextStep(){
    Rails.ajax({
      type: 'GET',
      url: appProps.reservationPath,
      dataType: 'json',
      success: res => {
        setReservation(res.reservation)
        setCurrentStep(prev => prev + 1)
        setLoading(false)
      }
    })
  }

  function couponsValidationAfterAction(result) {
    result.messages.forEach( message => {
      if (message.type === 'failure') {
        appProps.flashMessage.show(`${ message.value } ${ result.deletedCoupons.join(', ') }`, 'error')
      } else if (message.type === 'notice') {
        appProps.flashMessage.show(`${ message.value } ${ result.updatedCoupons.join(', ') }`, 'notice')
      }
    })

    setCouponAdjustments(result.couponAdjustments)
    setTotalWithAdjustments(result.newTotal)
    setLoading(false)
  }

  useEffect(() => {
    if (couponAdjustments.length) {
      setLoading(true)

      Rails.ajax({
        url: '/reservation/check_and_update_coupons',
        type: 'POST',
        dataType: 'json',
        success: res => {
          couponsValidationAfterAction(res)
        },
        error: res => {
          setLoading(false)
        }
      })
    }
  }, [])

  return(
    <>
      <div className='main-container'>
        { disabledCheckout ?
          <div className="main-container">
            <div className="not-found-wrapper flex-box flex-column items-center content-center">
              <h2>
                { appProps.translations.general.checkout_closed }
              </h2>
            </div>
          </div>
          :
          <div className='checkouts-wrapper'>
            <Summary
              appProps={ appProps }
              currentStep={ currentStep }
              reservation={ reservation }
              setLoading={ setLoading }
              couponAdjustments={ couponAdjustments }
              setCouponAdjustments={ setCouponAdjustments }
              totalWithAdjustments={ totalWithAdjustments }
              setTotalWithAdjustments={ setTotalWithAdjustments }
            />

            <div className="checkout-container">
              <div className="heading">
                <h1>
                  { appProps.translations.reservations.reservation_title }
                </h1>
              </div>
              <div className="checkout-steps">
                <SelectReservationStep
                  appProps={ appProps }
                  reservation={ reservation }
                  currentLocale={ appProps.currentLocale }
                  onSetCurrent={ onSetCurrent }
                  currentStep={ currentStep }
                />

                { reservation &&
                  <DetailsStep
                    appProps={ appProps }
                    reservationAddress={ reservation.address }
                    userAddress={  userAddress }
                    currentStep={ currentStep }
                    userEmail={ appProps.currentUserEmail }
                    onNextStep={ onNextStep }
                    onSetCurrent={ onSetCurrent }
                    setLoading={ setLoading }
                    associableAddressId={ reservation.associableAddressId }
                  />
                }

                { currentStep === 2 &&
                  <PaymentStep
                    appProps={ appProps }
                    stripePublishableKey={ stripePublishableKey }
                    orderId={ orderId }
                    type={ type }
                    paymentIframePath={ paymentIframePath }
                    cards={ cards }
                    setCurrentCardPath={ setCurrentCardPath }
                    cardsPath={ cardsPath }
                    addNewCardText={ addNewCardText }
                    guestCheckout={ !appProps.currentUserEmail }
                    canIssueInvoice={ canIssueInvoice }
                    billingAddress={ billingAddress }
                    setBillingAddress={ setBillingAddress }
                    contactDetails={ reservation.address }
                    invoice={ reservation.invoice }
                    showInvoiceForm={ showInvoiceForm }
                    setShowInvoiceForm={ setShowInvoiceForm }
                    setLoading={ setLoading }
                    currency={ reservation.currency }
                    couponsValidationAfterAction={ couponsValidationAfterAction }
                    legalTerms={ legalTerms }
                  />
                }
              </div>
            </div>
          </div>
        }
      </div>

      { loading &&
        <Loader
          absolute
          size={ 'large' }
          position={ 'center' }
          fullPage
        />
      }
    </>
  )
}
