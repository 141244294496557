import React from "react"

import { constructServicesListUrl } from "../../helpers/utils"
import { getPaginationOptions } from '@reactiveonline/reactive_admin_shared-packages/utils'

export default function Pagination({
  appProps, serviceParams
}) {

  function getPagesListItems(listItemsArray) {
    const currentPage = parseInt(serviceParams.page)

    return (listItemsArray.map( itemPage => {
      const urlParams = {
        keyword: serviceParams.keyword,
        order_by: serviceParams.orderBy,
        order: serviceParams.order,
        page: itemPage,
        per_page: serviceParams.perPage
      }

      return (
        <li key={ itemPage }>
          { itemPage > 0 && itemPage === currentPage &&
            <span className="page_numbers current-page">{ currentPage }</span>
          }

          { itemPage > 0 && itemPage !== currentPage &&
            <a className="page_numbers"
                href={ constructServicesListUrl(urlParams, appProps.localeUrlPresent ? appProps.currentLocale : null) }>
              { itemPage }
            </a>
          }

          { itemPage < 0 &&
            <span className="page_numbers">...</span>
          }
        </li>
      )
    }))
  }

  const currentPage = parseInt(serviceParams.page)
  let pagesListItems = getPagesListItems(getPaginationOptions(currentPage, serviceParams.totalPages))

  let pageList =
    <ul className="page_numbers">
      { pagesListItems }
    </ul>

  return (
    <section className="page-pagination">
      <nav className="page-navigation">
        { serviceParams.totalPages > 1 && pageList}
      </nav>
    </section>
  )
}
